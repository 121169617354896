import { HeroLayout } from '@xanadu_ai/xanadu-component-library'
import Flickity from 'react-flickity-component'
import ReactMarkdown from 'react-markdown'

import './Prizes.css'
import PrizesContent from '../../../content/LandingPage/Prizes'

const { title, description, headerImage, prizes } = PrizesContent

const flickityOptions = {
  initialIndex: 0,
  autoPlay: true,
  draggable: true,
  selectedAttraction: 0.01,
  friction: 0.25,
}

const Prizes = () => {
  return (
    <div className="Prizes">
      <HeroLayout
        backgroundImage={`/images/${headerImage}`}
        backgroundPosition={'bottom'}
        className="PrizesHero"
        size={'large'}
        data-testid="prizes-hero"
      ></HeroLayout>
      <div className="Prizes__content" id="prizes">
        <div className="Prizes__content__text">
          {title && <h3 className="text-3xl mb-4 font-[700]">{title}</h3>}
          {description && (
            <ReactMarkdown className="Prizes__content__text__description">
              {description}
            </ReactMarkdown>
          )}
        </div>
        <div className="Prizes__content__carousel">
          <Flickity
            className={'Prizes__carousel'}
            elementType={'div'}
            options={flickityOptions}
            disableImagesLoaded={false}
            reloadOnUpdate
            static
          >
            {prizes.map((prize, index) => (
              <div key={index} className="Prizes__carousel__item">
                <img
                  src={`/images/${prize.image}`}
                  className="max-w-[400px]"
                  alt={prize.title}
                />
                <p className="text-center mb-4 font-semibold">{prize.title}</p>
              </div>
            ))}
          </Flickity>
        </div>
      </div>
    </div>
  )
}

export default Prizes

import { init, captureMessage } from '@sentry/browser'
import config, { getCurrentEnv } from '../config'

export const initSentry = async () => {
  const sentry_config: {
    dsn: string
    environment: string
    ignoreErrors: string[]
  } = {
    dsn: config.sentry_dsn ? config.sentry_dsn : '',
    environment: getCurrentEnv(),
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],
  }
  init(sentry_config)
}

export const captureMessageSentry = (message: string) => {
  captureMessage(`${message}`)
}

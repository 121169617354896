import './PackingCard.css'

const PackingCard = ({
  image,
  imageAlt,
  title,
  text,
}: {
  image: string
  imageAlt: string
  title: string
  text: string
}) => {
  return (
    <div className="PackingCard">
      <div className="overflow-hidden">
        <img src={`/images/${image}`} alt={imageAlt} className="zoomElement" />
      </div>
      <div className="PackingCardText">
        <h4 className="font-semibold text-2xl sm:text-lg lg:text-2xl mb-2">
          {title}
        </h4>
        <p className="font-medium text-base sm:text-sm lg:text-base opacity-70">
          {text}
        </p>
      </div>
    </div>
  )
}

export default PackingCard

import Accordion from '../../../components/Accordion/Accordion'
import ReactMarkdown from 'react-markdown'

import './FAQSection.css'

import FAQSectionContent from '../../../content/LandingPage/FAQSection'

const { title, faqs, slackButtonUrl, slackButtonText } = FAQSectionContent

const FAQSection = () => {
  return (
    <div className="FAQ" id="faq">
      {title && <h2 className="text-3xl font-semibold mb-16">{title}</h2>}
      <div className="FAQ__content">
        {faqs.map((query, index) => (
          <Accordion
            key={index}
            className="FAQ__accordion"
            isExpandedDefault={false}
            collapsedContent={
              <p className="FAQ__question--preview">{query.question}</p>
            }
            iconPosition="right"
            fullyClickable
            expandedContent={
              <>
                <p className="FAQ__question">{query.question}</p>
                <ReactMarkdown
                  className="FAQ__answer-markdown"
                  linkTarget="_blank"
                >
                  {query.answer}
                </ReactMarkdown>
              </>
            }
            hideAccordionAriaLabel={`hide faq item ${index + 1}`}
            showAccordionAriaLabel={`show faq item ${index + 1}`}
          />
        ))}
      </div>
      {slackButtonText && slackButtonUrl && (
        <a
          className="FAQ__discussion_button"
          href={slackButtonUrl}
          target="_blank"
          rel="noopener noreferrer"
          data-testid="join-the-discussion-link"
        >
          {slackButtonText}
        </a>
      )}
    </div>
  )
}

export default FAQSection

import { DEV_ENV_TAGS, PROD_ENV_TAGS, STAGE_ENV_TAGS } from './constants'

type Keycloak = {
  realm: string
  url: string
  clientId: string
}

type Environment = {
  activeEventName: string
  keycloak: Keycloak
  baseUrl?: string
  graphqlUrl?: string
  mailchimpEndpoint?: string
  zendeskApiKey: string
  sentry_dsn?: string
  googleAnalyticsID?: string
}

const envs: { [key: string]: Environment } = {
  local: {
    activeEventName: 'Dummy Event 1',
    keycloak: {
      realm: 'platform',
      url: 'http://user-service:8400/',
      clientId: 'public',
    },
    sentry_dsn: '',
    baseUrl: 'http://localhost:3000/',
    graphqlUrl: 'http://localhost:3000/graphql',
    zendeskApiKey: 'bf432bdd-0f14-4c60-abf6-cdd652f2ac89',
    mailchimpEndpoint:
      'https://xanadu.us17.list-manage.com/subscribe/post?u=725f07a1d1a4337416c3129fd&amp;id=294b062630',
    googleAnalyticsID: '',
  },
  dev: {
    activeEventName: 'PennyLane Code Camp 2022',
    keycloak: {
      realm: 'platform',
      url: 'https://dev-platform.xanadu.ai/',
      clientId: 'public',
    },
    sentry_dsn:
      'https://d544e2df4c1d4f4db1e2fa9d90e1f64b@o280551.ingest.sentry.io/6750880',
    baseUrl: 'https://frvph0nt.dev.codecamp.xanadu.ai/',
    graphqlUrl: 'https://dev-platform.xanadu.ai/grader-service/graphql/',
    zendeskApiKey: 'bf432bdd-0f14-4c60-abf6-cdd652f2ac89',
    mailchimpEndpoint:
      'https://xanadu.us17.list-manage.com/subscribe/post?u=725f07a1d1a4337416c3129fd&amp;id=294b062630',
    googleAnalyticsID: '',
  },
  stage: {
    activeEventName: 'PennyLane Code Camp 2022',
    keycloak: {
      realm: 'platform',
      url: 'https://staging-platform.xanadu.ai/',
      clientId: 'public',
    },
    sentry_dsn:
      'https://d544e2df4c1d4f4db1e2fa9d90e1f64b@o280551.ingest.sentry.io/6750880',
    baseUrl: 'https://luckydumpling.staging.codecamp.xanadu.ai/',
    graphqlUrl: 'https://staging-platform.xanadu.ai/grader-service/graphql',
    zendeskApiKey: 'bf432bdd-0f14-4c60-abf6-cdd652f2ac89',
    mailchimpEndpoint:
      'https://xanadu.us17.list-manage.com/subscribe/post?u=725f07a1d1a4337416c3129fd&amp;id=294b062630',
    googleAnalyticsID: '',
  },
  production: {
    activeEventName: 'PennyLane Code Camp 2022',
    keycloak: {
      realm: 'platform',
      url: 'https://platform.xanadu.ai/',
      clientId: 'public',
    },
    sentry_dsn:
      'https://d544e2df4c1d4f4db1e2fa9d90e1f64b@o280551.ingest.sentry.io/6750880',
    baseUrl: 'https://codecamp.xanadu.ai/',
    graphqlUrl: 'https://platform.xanadu.ai/grader-service/graphql',
    zendeskApiKey: 'bf432bdd-0f14-4c60-abf6-cdd652f2ac89',
    mailchimpEndpoint:
      'https://xanadu.us17.list-manage.com/subscribe/post?u=725f07a1d1a4337416c3129fd&amp;id=294b062630',
    googleAnalyticsID: 'G-BKLN6MW0C5',
  },
}

export const getCurrentEnv = () => {
  // To be updated for stage and prod
  if (checkEnvTag(DEV_ENV_TAGS)) return 'dev'
  if (checkEnvTag(STAGE_ENV_TAGS)) return 'stage'
  if (checkEnvTag(PROD_ENV_TAGS)) return 'production'
  return 'local'
}

const checkEnvTag = (tags: string[]) => {
  const hostname = window.location.hostname
  return tags.some((tag) => hostname.includes(tag))
}

export default envs[getCurrentEnv()]

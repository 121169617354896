import { makeVar } from '@apollo/client'
import { IPlayerCurrent } from '../queries/userQueries'
import { eventDetails } from '../queries/eventQueries'

export interface StoreData {
  event: eventDetails
  playerCurrent: IPlayerCurrent
}

const initialState = {
  event: {
    id: '',
    name: '',
    status: '',
    registrationOpen: false,
    schedule: {
      startAt: '',
      finishAt: '',
    },
  },
  playerCurrent: {
    id: '',
    handle: '',
    team: {
      id: '',
      name: '',
    },
  },
}

export const AppStore = makeVar<StoreData>(initialState)

// These are all the fields for the titles and optional drop down menus in the registration FormData.
export default {
  title: 'Register for PennyLane Code Camp',
  subtitle: 'Please fill in the following optional information.',
  country: {
    title: 'What country will you be participating from?',
    // Country options are automatically populated
  },
  education: {
    title: 'What is your highest level of education?',
    options: ['High School', 'Undergraduate', 'Masters', 'PhD', 'Other'],
  },
  experience: {
    title: 'What is your experience with quantum computing?',
    options: [
      'None',
      'I have a bit of experience',
      `I'm a quantum computing enthusiast!`,
      'I work with quantum every day/employed in the industry',
    ],
  },
  background: {
    title: `What's your background?`,
    options: [
      'Student',
      'Researcher',
      'Developer',
      'Engineer',
      'Other',
    ],
  },
  referral: {
    title: 'How did you hear about this event?',
    options: [
      'Xanadu Newsletter',
      'Twitter',
      'LinkedIn',
      'Google',
      'Discord',
      'Slack',
      'Word of mouth',
      'Other',
    ],
  },
}

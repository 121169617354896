import { Card, Button } from '@xanadu_ai/xanadu-component-library'
import config from '../../../../config'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import RegistrationForm from './RegistrationForm/RegistrationForm'
import RegistrationFormContent from '../../../../content/LandingPage/RegistrationForm'
import RegistrationSuccessModalContent from '../../../../content/LandingPage/RegistrationSuccessModal'

import './RegistrationInfoModal.css'

interface RegistrationInfoModal {
  openModal: (value: boolean) => void
  isRegistered: boolean
}

const RegistrationInfoModal = ({
  openModal,
  isRegistered,
}: RegistrationInfoModal) => {
  return (
    <Card
      className={`${
        isRegistered ? 'min-h-0' : 'min-h-[600px]'
      } transition-all duration-500 ease-out`}
    >
      <>
        <Button
          onClick={() => openModal(false)}
          type="basic"
          icon={['fas', 'xmark']}
          className="text-lg absolute right-4 top-10"
          aria-label="close modal"
        />
        <div>
          <p className="h2 mb-4 text-center">
            {RegistrationFormContent['title']}
          </p>
          {isRegistered ? (
            <div className="w-full flex justify-center items-center">
              <div className="mr-2">
                <p>{RegistrationSuccessModalContent.successMessage}</p>
              </div>
              <div>
                <Button
                  label={RegistrationSuccessModalContent.successButtonLabel}
                  onClick={() => openModal(false)}
                  testId="close-modal"
                  className="PLCC-Button hover:bg-gold-1 border-none"
                />
              </div>
            </div>
          ) : (
            <p className="mb-4">{RegistrationFormContent['subtitle']}</p>
          )}
        </div>
        <div className={isRegistered ? 'hidden' : 'unset'}>
          <MailchimpSubscribe
            url={String(config.mailchimpEndpoint)}
            render={(props) => {
              // eslint-disable-next-line react/prop-types
              const { subscribe } = props
              return (
                <RegistrationForm
                  onValidated={(formData) => subscribe(formData)}
                />
              )
            }}
          />
        </div>
      </>
    </Card>
  )
}

export default RegistrationInfoModal

import axios from 'axios'
import KeycloakService from './KeycloakService'
import config, { getCurrentEnv } from '../config'

const platformUrls = [
  '/auth',
  ...(getCurrentEnv() !== 'local' ? ['/devices'] : []),
]

const excludeVersionHeader = ['/auth']

const isPlatformAPI = (url: string) => {
  const matches = platformUrls.filter((element) => {
    return url.indexOf(element) !== -1
  })
  return matches.length > 0
}

const addVersionHeader = (url: string) => {
  const matches = excludeVersionHeader.filter((element) => {
    return url.indexOf(element) !== -1
  })
  return matches.length === 0
}

const getBaseUrl = (apiUrl: string) => {
  if (isPlatformAPI(apiUrl)) {
    return config.keycloak.url
  } else {
    return config.baseUrl
  }
}

const http = axios.create()

export const initHttpClient = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  http.interceptors.request.use((config: { [key: string]: any }) => {
    if (KeycloakService.isLoggedIn()) {
      const cb = () => {
        config.baseURL = getBaseUrl(config.url)
        config.headers['Authorization'] = `Bearer ${KeycloakService.getToken()}`
        if (addVersionHeader(config.url)) {
          config.headers['Accept-Version'] = '0.4.0'
        } else {
          delete config.headers['Accept-Version']
        }
        return Promise.resolve(config)
      }
      return KeycloakService.updateToken(cb)
    } else {
      return config
    }
  })
}

export default http

import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
// import { useReactiveVar } from '@apollo/client'
// import { AppStore } from '../../services/apollo/store/store'
import { scrollToSelector } from '../../utils/SelectorActions'
import { NavBar, Button, Icon } from '@xanadu_ai/xanadu-component-library'
import { updateOverflowOfSelector } from '../../utils/SelectorActions'
// import KeycloakService from '../../services/KeycloakService'
import TopMenu from './TopMenu/TopMenu'
import SideMenu from './SideMenu/SideMenu'
import './Navbar.css'

export interface navLinkItem {
  title: string
  url: string
  enabled?: boolean
  icon?: JSX.Element
  id: string
  onClick?: () => void
  isActive: boolean
}

const Navbar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  // const store = useReactiveVar(AppStore)

  const navigateToHash = (selector: string) => {
    navigate('/')
    setTimeout(() => scrollToSelector(selector, 'smooth'), 100)
  }

  const [isOpenLeft, setIsOpenLeft] = useState<boolean>(false)

  const navLinks: navLinkItem[] = [
    {
      title: 'ABOUT',
      url: '/#about',
      enabled: true,
      icon: <Icon icon={['fa', 'fa-circle-info']} />,
      id: 'link-about',
      onClick: () => navigateToHash('#about'),
      isActive: `${location.pathname}${location.hash}` === '/#about',
    },
    {
      title: 'PRIZES',
      url: '/#prizes',
      enabled: true,
      icon: <Icon icon={['fa', 'fa-gift']} />,
      id: 'link-prizes',
      onClick: () => navigateToHash('#prizes'),
      isActive: `${location.pathname}${location.hash}` === '/#prizes',
    },
    {
      title: 'FAQ',
      url: '/#faq',
      enabled: true,
      icon: <Icon icon={['fa', 'fa-circle-question']} />,
      id: 'link-faq',
      onClick: () => navigateToHash('#faq'),
      isActive: `${location.pathname}${location.hash}` === '/#faq',
    },
    // {
    //   title: 'CODING CHALLENGES',
    //   url: `/dashboard/${store.playerCurrent.team.id ? 'home' : 'team'}`,
    //   icon: <Icon icon={['fa', 'fa-trophy']} />,
    //   enabled: KeycloakService.isLoggedIn() && !!store.playerCurrent.id,
    //   id: 'link-challenges',
    //   isActive: location.pathname.includes('/dashboard'),
    // },
  ]

  useEffect(() => {
    /** Close mobile menu on Escape key press */
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape' && isOpenLeft) {
        setIsOpenLeft(false)
      }
    })
  }, [isOpenLeft])

  return (
    <NavBar
      openLeftMenu={isOpenLeft}
      leftMenu={
        isOpenLeft ? (
          <SideMenu
            navLink={navLinks}
            isOpen={isOpenLeft}
            closeMenu={() => {
              setIsOpenLeft(false)
              updateOverflowOfSelector('#root', 'auto')
            }}
          />
        ) : (
          <></>
        )
      }
      closeMenu={() => {
        setIsOpenLeft(false)
        updateOverflowOfSelector('#root', 'auto')
      }}
    >
      <>
        <div className="hidden md:flex w-full">
          <TopMenu navLink={navLinks} />
        </div>
        <div className="flex text-white md:hidden w-full">
          <Button
            type="basic"
            className="p-1 text-xl"
            onClick={() => {
              if (!isOpenLeft) {
                setIsOpenLeft(true)
                updateOverflowOfSelector('#root', 'hidden', '100vh')
              } else {
                setIsOpenLeft(false)
              }
            }}
            leftIcon={['fa', 'fa-bars']}
            aria-expanded={isOpenLeft}
            aria-label={isOpenLeft ? 'close mobile menu' : 'open mobile menu'}
          />
        </div>
      </>
    </NavBar>
  )
}

export default Navbar

import { HeroLayout } from '@xanadu_ai/xanadu-component-library'
import LandingPageContent from '../../../content/LandingPage/LandingPageHero'
import StarBackground from '../../../components/StarBackground/StarBackground'
import ReactMarkdown from 'react-markdown'
import MarkdownRouterLink from '../../../components/MarkdownRouterLink/MarkdownRouterLink'
import './LandingPageHero.css'
import { useLocation } from 'react-router-dom'

const { backgroundImage, eventDate, title1, title2, subTitle, subTitle2 } =
  LandingPageContent

const LandingPageHero = () => {
  const { state } = useLocation()
  const previousPathname = JSON.stringify(state).split('"')[3]

  /** Don't run the title animation when navigating to the landing page from the dashboard. */
  const displayTitleAnimation = !previousPathname

  return (
    <StarBackground>
      <HeroLayout
        backgroundImage={`/images/${backgroundImage}`}
        backgroundPosition={'bottom'}
        className="LandingPageHero"
        size={'large'}
        data-testid="landing-page-hero"
      >
        <div className="LandingPageHero__content">
          {eventDate && (
            <h3
              className={`LandingPageHero__date ${
                displayTitleAnimation
                  ? 'animate__animated animate__fadeInDown'
                  : ''
              }`}
            >
              {eventDate}
            </h3>
          )}
          {(title1 || title2) && (
            <h1
              className={`LandingPageHero__title ${
                displayTitleAnimation
                  ? 'animate__animated animate__fadeInDown animate__delay-1s'
                  : ''
              }`}
            >
              {title1 && <span>{title1}</span>}
              {title2 && (
                <>
                  <br />
                  <span>{title2}</span>
                </>
              )}
            </h1>
          )}
          {subTitle && (
            <h2
              className={`LandingPageHero__subtitle ${
                displayTitleAnimation
                  ? 'animate__animated animate__fadeInDown animate__delay-2s'
                  : ''
              }`}
            >
              {subTitle}
            </h2>
          )}
          {subTitle2 && (
            <div
              className={`LandingPageHero__subtitle__markdown ${
                displayTitleAnimation
                  ? 'animate__animated animate__fadeInDown animate__delay-2s'
                  : ''
              }`}
            >
              <ReactMarkdown components={{ a: MarkdownRouterLink }}>
                {subTitle2}
              </ReactMarkdown>
            </div>
          )}
        </div>
      </HeroLayout>
    </StarBackground>
  )
}

export default LandingPageHero

import MarkdownLayout from '../../components/MarkdownLayout/MarkdownLayout'
import CodeOfConductContent from '../../content/CodeOfConduct.md'
import { useEffect } from 'react'

const CodeOfConduct = () => {
  useEffect(() => {
    document.title = 'Code of Conduct — PennyLane Code Camp'
  }, [])

  return (
    <MarkdownLayout
      markdownFile={CodeOfConductContent}
      pageTitle="Code of Conduct"
      className="-mt-8"
    />
  )
}

export default CodeOfConduct

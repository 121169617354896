import {
  Form,
  Button,
  Dropdown,
  Checkbox,
} from '@xanadu_ai/xanadu-component-library'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import countries from 'i18n-iso-countries'
import english from 'i18n-iso-countries/langs/en.json'
import { useReactiveVar } from '@apollo/client'
import { get } from 'lodash'
import { CREATE_PLAYER } from '../../../../../services/apollo/mutations/userMutations'
import KeycloakService from '../../../../../services/KeycloakService'
import { AppStore } from '../../../../../services/apollo/store/store'
import useMutationEvent from '../../../../../hooks/useMutationEvent'
import RegistrationFormContent from '../../../../../content/LandingPage/RegistrationForm'

import '../RegistrationInfoModal.css'

const EXCLUDED_COUNTRIES = [
  'Russian Federation',
  'Belarus',
  'North Korea',
  'Islamic Republic of Iran',
]

countries.registerLocale(english)
const countriesArray = Object.values(
  countries.getNames('en', { select: 'official' })
)

const COUNTRY_OPTIONS = countriesArray
  .filter((country: string) => !EXCLUDED_COUNTRIES.includes(country))
  .map((country: string) => ({
    label: country,
    value: country,
  }))

const EDUCATION_OPTIONS = RegistrationFormContent['education']['options'].map(
  (option: string) => ({
    label: option,
    value: option,
  })
)

const EXPERIENCE_OPTIONS = RegistrationFormContent['experience']['options'].map(
  (option: string) => ({
    label: option,
    value: option,
  })
)

const BACKGROUND_OPTIONS = RegistrationFormContent['background']['options'].map(
  (option: string) => ({
    label: option,
    value: option,
  })
)

const REFERRAL_OPTIONS = RegistrationFormContent['referral']['options'].map(
  (option: string) => ({
    label: option,
    value: option,
  })
)

interface Option {
  label: string
  value: string
}

interface MetaData {
  country?: string
  education?: string
  experience?: string
  background?: string
  referral?: string
  agreeToAllTerms?: boolean
}

interface SubmitError {
  field?: string
  message: string
}

interface DropdownTheme {
  colors: string[]
}

interface RegistrationFormProps {
  onValidated: (email: { EMAIL: string }) => void
}

const RegistrationForm = (props: RegistrationFormProps) => {
  const { onValidated } = props
  const store = useReactiveVar(AppStore)
  const [country, setCountry] = useState<Option | null>(null)
  const [education, setEducation] = useState<Option | null>(null)
  const [experience, setExperience] = useState<Option | null>(null)
  const [background, setBackground] = useState<Option | null>(null)
  const [referral, setReferral] = useState<Option | null>(null)

  const [agreeToAllTerms, toggleAgreeToAllTerms] = useState<boolean>(false)
  const [subscribe, toggleSubscribe] = useState<boolean>(false)
  const [submitDisabled, toggleSubmitDisabled] = useState<boolean>(true)
  const [submitErrors, setSubmitErrors] = useState<SubmitError[] | null>(null)
  const userEmail = KeycloakService.getTokenParsed()?.email

  useEffect(() => {
    toggleSubmitDisabled(!agreeToAllTerms)
  }, [agreeToAllTerms])

  const [createPlayer] = useMutationEvent(CREATE_PLAYER)

  const createMetaData = () => {
    const metaData: MetaData = {
      agreeToAllTerms,
    }

    if (country) metaData.country = country?.value
    if (education) metaData.education = education?.value
    if (experience) metaData.experience = experience?.value
    if (background) metaData.background = background?.value
    if (referral) metaData.referral = referral?.value

    return metaData
  }

  const submitForm = async () => {
    setSubmitErrors(null)
    if (
      !submitDisabled &&
      store.event.registrationOpen &&
      store.event.status !== 'FINISHED'
    ) {
      try {
        const metaData = createMetaData()
        const response = await createPlayer({
          variables: {
            meta: metaData,
          },
        })
        AppStore({
          ...store,
          playerCurrent: {
            ...store.playerCurrent,
            id: get(response, 'data.playerCreate.player.id', ''),
          },
        })
        const userErrors = get(response, 'data.playerCreate.userErrors')
        if (userErrors) setSubmitErrors(userErrors)
        if (subscribe) {
          const isFormValidated = onValidated({ EMAIL: userEmail })
          return isFormValidated
        }
      } catch (error) {
        setSubmitErrors([
          {
            message:
              'There was an error on the server, please try again later.',
          },
        ])
      }
    }
  }

  const handleCountryOnChange = (newValue: Option) => setCountry(newValue)
  const handleEducationOnChange = (newValue: Option) => setEducation(newValue)
  const handleExperienceOnChange = (newValue: Option) => setExperience(newValue)
  const handleBackgroundOnChange = (newValue: Option) => setBackground(newValue)
  const handleReferralOnChange = (newValue: Option) => setReferral(newValue)

  const getDropdownTheme = (theme: DropdownTheme) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: '#eba757',
        primary25: '#fabe7a',
        primary50: '#eba757',
        primary75: '#fabe7a',
      },
    }
  }

  return (
    <Form formName="Register" onSubmit={submitForm}>
      <>
        <div className="mb-4">
          <label className="mb-1 block" htmlFor="country">
            {RegistrationFormContent['country']['title']}
          </label>
          <Dropdown
            isSearchable={true}
            isClearable={true}
            onChange={handleCountryOnChange}
            options={COUNTRY_OPTIONS}
            value={country}
            isMulti={false}
            name="country"
            theme={(theme: DropdownTheme) => getDropdownTheme(theme)}
          />
        </div>
        <div className="mb-4">
          <label className="mb-1 block" htmlFor="education">
            {RegistrationFormContent['education']['title']}
          </label>
          <Dropdown
            isSearchable={false}
            isClearable={true}
            options={EDUCATION_OPTIONS}
            onChange={handleEducationOnChange}
            name="education"
            value={education}
            theme={(theme: DropdownTheme) => getDropdownTheme(theme)}
          />
        </div>
        <div className="mb-4">
          <label className="mb-1 block" htmlFor="experience">
            {RegistrationFormContent['experience']['title']}
          </label>
          <Dropdown
            isSearchable={false}
            isClearable={true}
            options={EXPERIENCE_OPTIONS}
            onChange={handleExperienceOnChange}
            value={experience}
            name="experience"
            theme={(theme: DropdownTheme) => getDropdownTheme(theme)}
          />
        </div>
        <div className="mb-4">
          <label className="mb-1 block" htmlFor="background">
            {RegistrationFormContent['background']['title']}
          </label>
          <Dropdown
            isSearchable={false}
            isClearable={true}
            options={BACKGROUND_OPTIONS}
            onChange={handleBackgroundOnChange}
            value={background}
            name="background"
            theme={(theme: DropdownTheme) => getDropdownTheme(theme)}
          />
        </div>
        <div className="mb-4">
          <label className="mb-1 block" htmlFor="referral">
            {RegistrationFormContent['referral']['title']}
          </label>
          <Dropdown
            isSearchable={false}
            isClearable={true}
            options={REFERRAL_OPTIONS}
            onChange={handleReferralOnChange}
            value={referral}
            name="referral"
            theme={(theme: DropdownTheme) => getDropdownTheme(theme)}
          />
        </div>
        <div className="mb-2">
          <Checkbox
            isChecked={agreeToAllTerms}
            onChange={toggleAgreeToAllTerms}
            fieldName="AllTerms"
            label={
              <>
                I have read and agreed with the{' '}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="/privacy-policy"
                  className="underline font-bold whitespace-nowrap"
                >
                  {' '}
                  privacy policy
                </Link>
                , the{' '}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="/code-of-conduct"
                  className="underline font-bold whitespace-nowrap"
                >
                  {' '}
                  code of conduct
                </Link>
                , and the{' '}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="/terms-and-conditions"
                  className="underline font-bold whitespace-nowrap"
                >
                  {' '}
                  terms and conditions
                </Link>
              </>
            }
            labelClasses="ml-2"
            testId="all-checkbox"
            required_front
            color="warning"
          />
        </div>
        <div className="mb-4">
          <Checkbox
            isChecked={subscribe}
            onChange={toggleSubscribe}
            fieldName="Subscribe"
            label="I want to subscribe to the monthly Xanadu newsletter"
            labelClasses="ml-2"
            color="warning"
          />
        </div>
        {submitErrors && (
          <div className="mb-4" data-testid="register-form-errors">
            <p className="text-error-1 font-semibold mb-2">
              The following error(s) occured:
            </p>
            <ul className="list-disc pl-4">
              {submitErrors.map((error, index) => (
                <li className="text-error-1" key={`error-${index}`}>
                  {error.message}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="flex justify-between flex-col-reverse sm:flex-row">
          <Button
            label="Register Now"
            buttonType="submit"
            disabled={submitDisabled}
            testId="register-submit"
            className="PLCC-Button hover:bg-gold-1 border-none disabled:bg-gray-4"
          />
          <div className="text-error-1 mt-6 mb-8 sm:my-auto">* Required</div>
        </div>
      </>
    </Form>
  )
}

export default RegistrationForm

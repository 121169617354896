// FAQ answers have markdown support

export default {
  title: `FAQs`,
  faqs: [
    {
      question: `What is the format of the event?`,
      answer:
        `The PennyLane Code Camp will consist of three weeks of quantum computing coding challenges, and new challenges will unlock each week. You can participate individually or as part of a team. There will be points for completing each challenge. In case of a tie, the team who takes the least time to complete the challenges will win, so don't wait until the last day to get started! The difficulty of the challenges will increase each week, but it could take anywhere from a couple of minutes to a couple of hours to solve a challenge. It is up to you to decide how much time you would like to put in; simply choose a challenge to start with, and go from there. There is no requirement to try to solve every challenge, but badges and certificates are up for grabs!`,
    },
    {
      question: `What is the schedule and deadline to register for the PennyLane Code Camp?`,
      answer:
        `The PennyLane Code Camp will take place November 1st–21st 2022. Participants can register at any time, up until the last day of the Code Camp (November 21st). New challenges will unlock at 9:00 a.m. ET on November 1st, November 7th, and November 14th. All challenges close at 5:59 p.m. ET on November 21st 2022.`,
    },
    {
      question: `Does it cost anything to attend this event?`,
      answer: `No, participation is FREE for everyone!`,
    },
    {
      question:
        `What prizes can I win? Will there also be certificates and badges?`,
      answer:
        `Prizes include but are not limited to: a guaranteed interview for the 2023 Xanadu Residency Program (if eligible), consulting time with Xanadu researchers or developers who are leaders in their respective fields, a copy of Maria Schuld’s book _Supervised Learning with Quantum Computers_, a swag pack featuring an exclusive PennyLane tee, trees planted in your name in the Xanadu Forest via TreeNation, a Xanadu lab tour (in person or virtual) and a Dock & Bay quick-drying towel for sports, gym, travel, yoga, camping, or swimming. Badges and certificates are also up for grabs! Answer all of the Explorer challenges and you've bagged yourself a badge. Two more badges are available for the Adventurer and Pioneer challenges, while the bravest among you can earn a certificate by solving every challenge.`,
    },
    {
      question: `How do I know if I have successfully signed up? Will I get any updates?`,
      answer:
        `You should be able to see the option of creating or joining a team on the Code Camp home page. Once you've signed up, you will receive weekly updates by email.`,
    },
    {
      question: `What skill level do I need to participate and how can I prepare?`,
      answer:
        `There is something for everyone, from complete beginners to seasoned experts. Check out the [PennyLane](https://pennylane.ai/) website and the [Xanadu Quantum Codebook](https://codebook.xanadu.ai/) to prepare.`,
    },
    {
      question: `What software do I need to use for the event?`,
      answer:
        `The PennyLane Code Camp challenges are based on PennyLane. You will be able to submit solutions to challenges directly from the Code Camp website without needing to install anything on your device. Alternatively, [Notebooks](https://pennylane.xanadu.ai) on Xanadu Cloud are available with PennyLane pre-installed, providing a sandbox for you to test solutions or simply learn more about PennyLane!`,
    },
    {
      question: `How do teams work and how can I find a team?`,
      answer:
        `You can create your own team and (optionally) invite people to join it, or you can join an existing team. Teams can have 1–4 team members. Individuals can take part in the code camp challenges independently by creating a team but not inviting anyone to join it. There is no distinction on the scoreboard between teams of one and teams with multiple members. Recruit friends, colleagues, classmates, or put the call out in existing communities such as the [PennyLane discussion forum](https://discuss.pennylane.ai/), the [Xanadu Slack](https://join.slack.com/t/xanadu-quantum/shared_invite/zt-1hzybvv2e-1ym~kimhOTT8mbBFJgiq~A), or the [Unitary Fund Discord server](http://discord.unitary.fund/). Each team will have a unique team code so anyone who has the code can join your team as long as it has fewer than 4 team members. If 4 team members have already joined or if the team is 'locked', an error message will appear if anyone else tries to use the team code.`,
    },
    {
      question: `My team name has already been taken! What do I do?`,
      answer:
        `Team names are unique; the only way to guarantee your favourite name in the PennyLane Code Camp is to register and create a team early. If your team has multiple members, check if one of your teammates has already created a team and ask them to share the team code with you.`,
    },
    {
      question:
        `How can I connect with others who are taking part in the PennyLane Code Camp?`,
      answer:
        `For general discussion about the PennyLane Code Camp, join the Xanadu Slack and introduce yourself on the [#codecamp](https://join.slack.com/t/xanadu-quantum/shared_invite/zt-1hzybvv2e-1ym~kimhOTT8mbBFJgiq~A) channel.`,
    },
    {
      question:
        `Can I change the uneditable code in a coding challenge?`,
      answer:
        `You can only put your solution to the coding challenge in the designated area. However, if you want to test and prototype your solution outside of the user interface, click the "Open a Notebook" button at the bottom of the webpage of the coding challenge. This will open a Notebook on Xanadu Cloud that functions exactly like a regular Jupyter Notebook. Modify the code to your heart's content, noting that you can only paste code back into the challenge editor in the designated area.`,
    },
    {
      question:
        `Can I try out my solution to a coding challenge on my own computer?`,
      answer:
        `At the bottom of the page for a specific coding challenge, there is a button that says "Copy all". If you click that, the entire code you see can be pasted into your favourite IDE (e.g., VSCode, Sublime, VIM, etc.). Modify the code to your heart's content, noting that you can only paste code back into the challenge editor in the designated area. `,
    },
    {
      question: `How will the ranking be computed?`,
      answer:
        `Solving challenges earns your team points and all the points you earn count toward your total. However, the longer you take to solve a challenge, the lower your ranking will be compared to other teams with the same score! This means that, between two teams that have the same number of points, the team that takes the least amount of time to solve each of the challenges will be ranked higher.`,
    },
    {
      question: `How is Time calculated in the leaderboard?`,
      answer:
        `The clock started at 9:00 a.m. ET on November 1st (when the PennyLane Code Camp started). Total time = time to solve challenge 1 + time to solve challenge 2 + time to solve challenge 3, etc. The time to solve each challenge is calculated as the number of minutes elapsed from the beginning of the event until you have successfully submitted a solution.`,
    },
  ],
  slackButtonUrl:
    `https://join.slack.com/t/xanadu-quantum/shared_invite/zt-1hzybvv2e-1ym~kimhOTT8mbBFJgiq~A`,
  slackButtonText: `Join the discussion`,
}

import { NavLink } from 'react-router-dom'
import { navLinkItem } from '../Navbar'
// import { Button } from '@xanadu_ai/xanadu-component-library'
import KeycloakService from '../../../services/KeycloakService'
// import { getWindowOrigin } from '../../../utils/WindowActions'
import './SideMenu.css'

const SideMenu = ({
  navLink,
  closeMenu,
  isOpen,
}: {
  navLink: navLinkItem[]
  closeMenu: () => void
  isOpen: boolean
}) => {
  return (
    <div
      className={`flex flex-col h-full ${
        !KeycloakService.isLoggedIn() ? 'pt-4' : ''
      }`}
    >
      {KeycloakService.isLoggedIn() && (
        <div className="SideMenu__username">
          {`${KeycloakService.getTokenParsed()?.given_name} ${
            KeycloakService.getTokenParsed()?.family_name
          }`}
        </div>
      )}
      <div className="SideMenu__items">
        {navLink.map(
          (link) =>
            link.enabled && (
              <NavLink
                key={link.id}
                data-testid={link.id}
                to={link.url}
                className={`SideMenu__item ${
                  link.isActive ? 'SideMenu__item--active' : ''
                }`}
                onClick={() => {
                  closeMenu()
                  link.onClick ? link.onClick() : () => null
                }}
                tabIndex={isOpen ? 0 : -1}
              >
                <span className="SideMenu__item__icon">{link.icon}</span>
                <span className="SideMenu__item__text">{link.title}</span>
              </NavLink>
            )
        )}
      </div>
      {/* <div className="SideMenu__logout">
        {KeycloakService.isLoggedIn() ? (
          <Button
            label="SIGN OUT"
            leftIcon={['fa', 'fa-arrow-right-from-bracket']}
            type="basic"
            onClick={() => KeycloakService.doLogout(getWindowOrigin())}
          />
        ) : (
          <Button
            label="SIGN IN"
            leftIcon={['fa', 'fa-arrow-right-from-bracket']}
            type="basic"
            onClick={() => KeycloakService.doLogin()}
          />
        )}
      </div> */}
    </div>
  )
}

export default SideMenu

import { gql } from '@apollo/client'

export interface IPlayerCurrent {
  id: string
  handle: string
  team: {
    id: string
    name: string
  }
}

export const GET_PLAYER_CURRENT = gql`
  query GetPlayerCurrent {
    playerCurrent {
      handle
      id
      team {
        id
        name
      }
    }
  }
`

import './polyfill'
import '@xanadu_ai/xanadu-component-library/dist/typography.css'
import '@xanadu_ai/xanadu-component-library/dist/index.css'
import 'react-toastify/dist/ReactToastify.css'
import 'flickity/dist/flickity.css'
import './index.css'
import './animation.css'
import 'animate.css'
import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import KeycloakService from './services/KeycloakService'
import apolloClient from './services/apollo/client'
import { CookiesProvider } from 'react-cookie'
import { initSentry } from './services/SentryService'
import {
  consoleLogEasterEgg,
  addKonamiCodeListener,
  removeCursorStyles,
  addCustomCursor,
} from '@xanadu_ai/web-easter-eggs'
import { getCurrentEnv } from './config'

const bootLoadApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <ApolloProvider client={apolloClient}>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </ApolloProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

// Initialize keycloak and boot App
KeycloakService.initKeycloak(bootLoadApp)
if (getCurrentEnv() !== `local`) initSentry()
if (consoleLogEasterEgg) consoleLogEasterEgg('xanadu')
if (addKonamiCodeListener && addCustomCursor)
  addKonamiCodeListener(() => {
    if (document && document.querySelectorAll('canvas').length)
      removeCursorStyles()
    else addCustomCursor('fairydust')
  })
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

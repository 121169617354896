import StarbackgroundImage from '../../assets/images/background/stars.png'

const StarBackground = ({
  children,
}: {
  children: React.ReactNode | string | null
}) => {
  return (
    <div
      className="skyAnimation"
      style={{
        backgroundImage: `url(${StarbackgroundImage})`,
        backgroundColor: '#000',
      }}
    >
      {children}
    </div>
  )
}

export default StarBackground

/* eslint-disable @typescript-eslint/no-explicit-any */
import Keycloak from 'keycloak-js'
import config from '../config'

const _kc = new Keycloak({
  realm: config.keycloak.realm,
  url: `${config.keycloak.url}auth/`,
  clientId: config.keycloak.clientId,
})

const initKeycloak = (onAuthenticatedCallback: () => void) => {
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + '/silent-check-sso.html',
      checkLoginIframe: false,
    })
    .then(() => {
      onAuthenticatedCallback()
    })
    .catch(console.error)
}

const doLogin = (redirectUri?: string) =>
  redirectUri ? _kc.login({ redirectUri }) : _kc.login()

const doLogout = (redirectUri?: string) => {
  _kc.logout({ redirectUri: redirectUri ? redirectUri : config.baseUrl })
}

const getToken = () => {
  return _kc.token
}

const getAuthServerUrl = () => _kc.authServerUrl

const getTokenParsed = () => _kc.tokenParsed

const getRealm = () => _kc.realm

const getClientId = () => _kc.clientId

const isLoggedIn = () => !!_kc.token

const isAuthenticated = () => _kc.authenticated

const updateToken = (successCallback: () => void) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin)

const hasRole = (roles: []) => roles.some((role) => _kc.hasRealmRole(role))

const KeycloakService = {
  keycloakInstance: _kc,
  doLogin,
  doLogout,
  initKeycloak,
  getAuthServerUrl,
  getRealm,
  getClientId,
  getToken,
  getTokenParsed,
  isLoggedIn,
  isAuthenticated,
  updateToken,
  hasRole,
}

export default KeycloakService

import { useEffect } from 'react'
import MarkdownLayout from '../../components/MarkdownLayout/MarkdownLayout'
import PrivacyPolicyContent from '../../content/PrivacyPolicy.md'

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title = 'Privacy Policy — PennyLane Code Camp'
  }, [])

  return (
    <MarkdownLayout
      markdownFile={PrivacyPolicyContent}
      pageTitle="Privacy Policy"
      className="-mt-8"
    />
  )
}

export default PrivacyPolicy

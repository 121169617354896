export const scrollToSelector = (
  selector: string,
  scrollBehavior: 'auto' | 'smooth' = 'auto',
  scrollToBottom = false
) => {
  const element = document.querySelector<HTMLElement>(selector)
  if (element) {
    setTimeout(() => {
      element.scrollIntoView({
        block: scrollToBottom ? 'end' : 'start',
        behavior: scrollBehavior,
      })
    })
  }
}

export const updateOverflowOfSelector = (
  selector: string,
  overflowBehavior: 'auto' | 'hidden' | 'scroll',
  maxHeight = '100%'
) => {
  const element = document.querySelector<HTMLElement>(selector)
  if (element) {
    element.style.overflow = overflowBehavior
    element.style.maxHeight = maxHeight
  }
}

import './PackingList.css'
import { NavLink } from 'react-router-dom'
import { HeroLayout } from '@xanadu_ai/xanadu-component-library'
import { scrollToSelector } from '../../../utils/SelectorActions'
import PackingListContent from '../../../content/LandingPage/PackingList'
import PackingCard from './PackingCard'
import { useReactiveVar } from '@apollo/client'
import { AppStore } from '../../../services/apollo/store/store'
import KeycloakService from '../../../services/KeycloakService'
import { REGISTER_HASH } from '../Registration/Registration'
import config from '../../../config'

const { title, subTitle, cards, headerImage } = PackingListContent

const PackingList = () => {
  const store = useReactiveVar(AppStore)
  return (
    <>
      <HeroLayout
        backgroundImage={`/images/${headerImage}`}
        backgroundPosition={'bottom'}
        className="PackingListHero"
        size={'large'}
        data-testid="packing-list-hero"
      ></HeroLayout>
      <div className="PackingList">
        <div className="PackingHeaders">
          {title && <h2 className="text-3xl font-semibold mb-6">{title}</h2>}
          {subTitle && (
            <h3 className="opacity-60 text-2xl font-medium">{subTitle}</h3>
          )}
        </div>
        {cards.length > 1 && (
          <div className="PackingCards">
            {cards.map((card, index) =>
              card.link ? (
                card.isExternalLink ? (
                  <a
                    key={index}
                    href={card.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PackingCard
                      image={card.image}
                      imageAlt={card.imageAlt}
                      title={card.header}
                      text={card.text}
                    />
                  </a>
                ) : (
                  <NavLink
                    key={index}
                    to={
                      card.openRegistrationModalIfNotRegistered &&
                      !store.playerCurrent.id
                        ? KeycloakService.isLoggedIn()
                          ? `/${REGISTER_HASH}`
                          : ''
                        : card.link
                    }
                    onClick={() => {
                      if (
                        card.openRegistrationModalIfNotRegistered &&
                        !KeycloakService.isLoggedIn()
                      ) {
                        KeycloakService.doLogin(
                          `${
                            config.baseUrl
                          }?registeredRedirectURL=${encodeURIComponent(
                            card.link
                          )}${REGISTER_HASH}`
                        )
                      } else {
                        scrollToSelector('#container', 'smooth')
                      }
                    }}
                  >
                    <PackingCard
                      image={card.image}
                      imageAlt={card.imageAlt}
                      title={card.header}
                      text={card.text}
                    />
                  </NavLink>
                )
              ) : (
                <div>
                  <PackingCard
                    image={card.image}
                    imageAlt={card.imageAlt}
                    title={card.header}
                    text={card.text}
                  />
                </div>
              )
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default PackingList

import { useState, useEffect } from 'react'
import { Modal } from '@xanadu_ai/xanadu-component-library'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { AppStore } from '../../../services/apollo/store/store'
import KeycloakService from '../../../services/KeycloakService'
import RegistrationInfoModal from './RegistrationModal/RegistrationInfoModal'
import config from '../../../config'
import RegistrationContent from '../../../content/LandingPage/Registration'
import { scrollToSelector } from '../../../utils/SelectorActions'

import './Registration.css'

export const REGISTER_HASH = `#register`
const ANIMATION_DURATION = 400
const REGISTER_BUTTON_STYLES = { transitionDuration: `${ANIMATION_DURATION}ms` }

const {
  title,
  description,
  footerText,
  registerButtonText,
  registerButtonDescription,
  registeredText,
  joinTeamButtonText,
  viewChallengesButtonText,
  image,
  imageAlt,
  slackButtonUrl,
  slackButtonText,
} = RegistrationContent

const Registration = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const store = useReactiveVar(AppStore)
  const [showRegistrationButton, toggleShowRegistrationButton] =
    useState<boolean>(true)
  const [showUserDetailsModal, toggleShowUserDetailsModal] =
    useState<boolean>(false)
  const [isUserRegistered, updateRegisterStatus] = useState<boolean>(false)

  useEffect(() => {
    if (location.hash === `${REGISTER_HASH}`) {
      const redirectURL = searchParams.get('registeredRedirectURL')
      if (store.playerCurrent.id && redirectURL) {
        navigate(redirectURL)
      }
      scrollToSelector('#about')
      toggleShowUserDetailsModal(true)
    }
  }, [location.hash])

  useEffect(() => {
    updateRegisterStatus(store.playerCurrent.id ? true : false)
  }, [store.playerCurrent.id])

  useEffect(() => {
    if (isUserRegistered) {
      const showRegistrationButtonTimeout = setTimeout(() => {
        toggleShowRegistrationButton(!isUserRegistered)
        clearTimeout(showRegistrationButtonTimeout)
      }, ANIMATION_DURATION)
    }
  }, [isUserRegistered])

  const handleRegister = () => {
    if (KeycloakService.isAuthenticated()) {
      toggleShowUserDetailsModal(true)
      navigate(`/${REGISTER_HASH}`)
    } else {
      KeycloakService.doLogin(`${config.baseUrl}/${REGISTER_HASH}`)
    }
  }

  const handleClose = () => {
    toggleShowUserDetailsModal(false)
    navigate('/')
    scrollToSelector('#about')
  }

  return (
    <section className="Registration" id="about">
      <div className="Registration__info-wrapper">
        {title && <h2 className="Registration__title">{title}</h2>}
        {description && (
          <p className="Registration__description">{description}</p>
        )}
        <p className="Registration__subtext">
          PennyLane Code Camp has now ended. We hope that you enjoyed the event!
        </p>
        {store.event.id &&
          store.event.registrationOpen &&
          store.event.status !== 'FINISHED' && (
            <>
              {footerText && (
                <p className="Registration__footnotes">{footerText}</p>
              )}
              {showRegistrationButton ? (
                <div
                  style={REGISTER_BUTTON_STYLES}
                  className={`Registration__register-btn-wrapper ${
                    !isUserRegistered ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <button
                    data-testid="register-now-button"
                    className="PLCC-Button PLCC-Button--register"
                    onClick={() => handleRegister()}
                  >
                    {registerButtonText}
                  </button>
                  {registerButtonDescription && (
                    <small className="block text-center mt-2 mb-6">
                      {registerButtonDescription}
                    </small>
                  )}
                </div>
              ) : (
                <div
                  style={REGISTER_BUTTON_STYLES}
                  className={`${
                    isUserRegistered ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  {registeredText && (
                    <p className="Registration__registered-text">
                      {registeredText}
                    </p>
                  )}
                  <div className="flex flex-col md:flex-row justify-start flex-wrap mt-6">
                    <Link
                      data-testid="view-dashboard-button"
                      to={`/dashboard/${
                        store.playerCurrent.team?.id ? 'home' : 'team'
                      }`}
                      className="PLCC-Button-Secondary PLCC-Button-Secondary--register md:mr-10"
                    >
                      {store.playerCurrent.team?.id
                        ? viewChallengesButtonText
                        : joinTeamButtonText}
                    </Link>
                    <a
                      data-testid="join-discussion-button"
                      href={slackButtonUrl}
                      target="_blank"
                      className="PLCC-Button-Secondary PLCC-Button-Secondary--register"
                      rel="noreferrer"
                    >
                      {slackButtonText}
                    </a>
                  </div>
                </div>
              )}
            </>
          )}
      </div>
      {image && imageAlt && (
        <div className="Registration__image-wrapper">
          <img
            data-testid="registration-image"
            src={`/images/${image}`}
            alt={imageAlt}
            className="Registration__image"
          />
        </div>
      )}
      <Modal
        open={showUserDetailsModal}
        modal
        closeOnDocumentClick={false}
        closeOnEscape={true}
        className="RegistrationInfoModal"
        overlayStyle={{ overflow: 'auto', scrollPadding: '0px' }}
        onClose={() => handleClose()}
        lockScroll
      >
        <RegistrationInfoModal
          openModal={toggleShowUserDetailsModal}
          isRegistered={isUserRegistered}
        />
      </Modal>
    </section>
  )
}

export default Registration

import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface MarkdownRouterLinkProps {
  children: ReactNode[]
  href?: string
}

const MarkdownRouterLink = (props: MarkdownRouterLinkProps) => {
  const { children, href } = props
  if (href) {
    return href.match(/^(https?:)?\/\//) ? (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    ) : (
      <Link to={href}>{children}</Link>
    )
  } else return null
}

export default MarkdownRouterLink

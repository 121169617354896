import { useEffect } from 'react'
import { Card, Icon } from '@xanadu_ai/xanadu-component-library'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { AppStore } from '../../services/apollo/store/store'
import ScrollContainer from 'react-indiana-drag-scroll'

import './NavigationCard.css'

const NavigationCard = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const store = useReactiveVar(AppStore)

  const NAV_LINKS = [
    {
      title: 'Rank',
      testId: 'tab-rank',
      url: '/dashboard/home',
      icon: ['fas', 'fa-star'],
      additionalIsActiveConditions: null,
      enabled: !!(store.playerCurrent.id && store.playerCurrent.team.id),
    },
    {
      title: 'Team',
      testId: 'tab-team',
      url: '/dashboard/team',
      icon: ['fas', 'fa-users'],
      additionalIsActiveConditions: null,
      enabled: !!store.playerCurrent.id,
    },
    {
      title: 'Challenges',
      testId: 'tab-challenges',
      url: '/dashboard/challenges',
      icon: ['fas', 'fa-trophy'],
      additionalIsActiveConditions: pathname.includes('/dashboard/challenge/'),
      enabled: !!(store.playerCurrent.id && store.playerCurrent.team.id),
    },
    {
      title: 'Submissions',
      testId: 'tab-submissions',
      url: '/dashboard/submissions',
      icon: ['fas', 'fa-circle-check'],
      additionalIsActiveConditions: null,
      enabled: !!(store.playerCurrent.id && store.playerCurrent.team.id),
    },
    {
      title: 'Support',
      testId: 'tab-support',
      url: '/dashboard/support',
      icon: ['fas', 'fa-comments'],
      additionalIsActiveConditions: null,
      enabled: !!store.playerCurrent.id,
    },
  ]

  useEffect(() => {
    if (store.playerCurrent.id && pathname) {
      const restrictedLink = NAV_LINKS.find(
        (link) => pathname.includes(link.url) && !link.enabled
      )
      if (restrictedLink) {
        // if dashboard route and user registered take to teams page
        if (
          store.playerCurrent.id &&
          restrictedLink.url.includes('dashboard')
        ) {
          navigate('/dashboard/team')
        } else {
          navigate('/')
        }
      }
    }
  }, [pathname, store.playerCurrent.id])

  return (
    <ScrollContainer className="NavigationCardContainer">
      <Card shadow="small" className="NavigationCard">
        <div className="flex justify-around lg:pb-0 w-full min-w-max">
          {NAV_LINKS.map((link, index) => (
            <div
              className={`flex justify-center mx-[7px] lg:mx-0 ${
                link.enabled ? '' : 'cursor-not-allowed'
              }`}
              key={`nav-link-${index}`}
            >
              <NavLink
                data-testid={link.testId}
                to={link.url}
                tabIndex={link.enabled ? 0 : -1}
                className={({ isActive }) =>
                  `NavigationCard__Link ${
                    isActive || link.additionalIsActiveConditions
                      ? 'NavigationCard__Link--isActive'
                      : ''
                  }
              ${link.enabled ? '' : 'NavigationCard__Link--isDisabled'}`
                }
              >
                <Icon icon={link.icon} className="NavigationCard__Link__Icon" />
                <span className="NavigationCard__Link__Text">{link.title}</span>
              </NavLink>
            </div>
          ))}
        </div>
      </Card>
    </ScrollContainer>
  )
}

export default NavigationCard

export default {
  title: 'Packing list',
  subTitle: 'the when, what, how',
  headerImage: 'background_spacer_2.png',
  cards: [
    {
      image: 'packing_list_schedule.png',
      imageAlt: 'Young woman traveler looking at a map in the woods',
      header: 'Schedule',
      text: 'Mark your calendars and join us from November 1st – 21st for three weeks of coding challenge competition.',
      link: 'https://calendar.google.com/calendar/embed?src=c_771efad3bf94f953f990b05352495115fc25f2789cbeda779cd7612d5bd1a539%40group.calendar.google.com&ctz=America%2FToronto',
      isExternalLink: true,
      openRegistrationModalIfNotRegistered: false,
    },
    {
      image: 'packing_list_challenges.png',
      imageAlt: 'Compass on a log with moss',
      header: 'Coding Challenges',
      text: 'Three categories, 15 unique questions. Varied difficulty levels.',
      link: '',
      isExternalLink: false,
      openRegistrationModalIfNotRegistered: true,
    },
    {
      image: 'packing_list_prepare.png',
      imageAlt: 'Sunbeams shining through a misty forest',
      header: 'Get prepared',
      text: 'Whether you’re a beginner or expert, our evolving suite of educational tools has got you covered.',
      link: 'https://pennylane.ai/',
      isExternalLink: true,
      openRegistrationModalIfNotRegistered: false,
    },
  ],
}

import { useEffect, useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { GET_EVENT_ID } from '../../services/apollo/queries/eventQueries'
import { AppStore } from '../../services/apollo/store/store'
import { eventDetails } from '../../services/apollo/queries/eventQueries'
import FullScreenLoader from '../FullScreenLoader/FullScreenLoader'
import config from '../../config'

interface LayoutProps {
  children: React.ReactNode | string | null
}

const EventRoute = ({ children }: LayoutProps) => {
  const store = useReactiveVar(AppStore)
  const [isEventIdSet, toggleIsEventIdSet] = useState<boolean>(false)
  const { data, loading } = useQuery(GET_EVENT_ID)

  const getCurrentEvent = (events: eventDetails[]) => {
    const currentEvent = events.find(
      (eventItem: eventDetails) => eventItem.name === config.activeEventName
    )
    return currentEvent ? currentEvent : store.event
  }

  useEffect(() => {
    if (data && data.events && data.events.length > 0) {
      const event = getCurrentEvent(data.events)
      AppStore({ ...store, event })
    }
    toggleIsEventIdSet(true)
  }, [data])
  return <>{!loading && isEventIdSet ? children : <FullScreenLoader />}</>
}

export default EventRoute

import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { GET_PLAYER_CURRENT } from '../../services/apollo/queries/userQueries'
import { AppStore } from '../../services/apollo/store/store'
import useQueryEvent from '../../hooks/useQueryEvent'
import { get } from 'lodash'
import FullScreenLoader from '../FullScreenLoader/FullScreenLoader'

interface LayoutProps {
  children?: React.ReactNode | string
}

const PlayerRoute = ({ children = null }: LayoutProps) => {
  const store = useReactiveVar(AppStore)
  const [isPlayerDataSet, toggleIsPlayerDataSet] = useState<boolean>(false)
  const { data, loading } = useQueryEvent(GET_PLAYER_CURRENT, {
    fetchPolicy: 'network-only',
  })
  const navigate = useNavigate()
  const location = useLocation()

  const checkIfRegisteredRoutesAccessible = () => {
    if (
      location.pathname.includes('/dashboard') &&
      data &&
      !data.playerCurrent &&
      !loading
    ) {
      navigate('/')
    }
  }

  useEffect(() => {
    checkIfRegisteredRoutesAccessible()
  }, [data, loading])

  useEffect(() => {
    if (get(data, 'playerCurrent.id')) {
      const playerCurrent = {
        id: get(data, 'playerCurrent.id'),
        handle: get(data, 'playerCurrent.handle', ''),
        team: {
          id: get(data, 'playerCurrent.team.id', ''),
          name: get(data, 'playerCurrent.team.name', ''),
        },
      }
      AppStore({ ...store, playerCurrent })
    }
    toggleIsPlayerDataSet(true)
  }, [data])
  return <>{!loading && isPlayerDataSet ? children : <FullScreenLoader />}</>
}

export default PlayerRoute

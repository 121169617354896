import ReactGA from 'react-ga4'
import config from '../config'
import KeycloakService from './KeycloakService'

export const initGA = () => {
  if (config.googleAnalyticsID && KeycloakService.isLoggedIn()) {
    ReactGA.initialize(config.googleAnalyticsID)
  }
}

export const trackPageView = (pageURL: string) => {
  if (pageURL && KeycloakService.isLoggedIn()) {
    ReactGA.send({ hitType: 'pageview', page: pageURL })
  }
}

export const trackUserEvent = (
  category: string,
  action: string,
  label?: string
) => {
  ReactGA.event({
    category,
    action,
    label,
  })
}

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { trackPageView } from '../services/GoogleAnalytics'

export const usePageTracking = (): void => {
  const location = useLocation()

  useEffect(() => {
    trackPageView(`${location.pathname + location.search + location.hash}`)
  }, [location])
}

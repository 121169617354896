export default {
  title: 'A camp for coding pioneers',
  description: `In celebration of PennyLane's fourth birthday, we are taking a three-week virtual trip to the great outdoors. Test your coding skills solo or in teams during our weekly challenges. Compete for prizes, earn badges for each coding difficulty level you master, and move up the leaderboard in our first-ever PennyLane Code Camp.`,
  footerText: 'Online | November 1st – 21st 2022 | Free',
  registerButtonText: 'Register Now',
  registerButtonDescription: 'Register through Xanadu Cloud',
  registeredText: "You have registered. What's next?",
  joinTeamButtonText: 'Create or join a team',
  viewChallengesButtonText: 'View challenges',
  image: 'registration_image.jpg',
  imageAlt: 'Campfire',
  slackButtonUrl: 'https://xanadu-quantum.slack.com/join/shared_invite/zt-nkwn25v9-H4hituCb_PUj4idG0MhSug#/shared-invite/email',
  slackButtonText: 'Join the discussion',
}

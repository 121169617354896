import { useState } from 'react'
import { Icon } from '@xanadu_ai/xanadu-component-library'
import './Accordion.css'

interface AccordionProps {
  /** Content to display when the accordion is expanded */
  expandedContent: JSX.Element
  /** Optionally display alternative content when the accordion is collapsed */
  collapsedContent?: JSX.Element
  /** className for styling */
  className?: string
  /** Is expanded by default */
  isExpandedDefault?: boolean
  /** Icon position */
  iconPosition?: 'left' | 'right'
  /** Make entire accordion clickable */
  fullyClickable?: boolean
  /** Aria label text for collapsed button*/
  hideAccordionAriaLabel?: string
  /** Aria label text for expand button*/
  showAccordionAriaLabel?: string
}

const Accordion = (props: AccordionProps) => {
  const {
    expandedContent,
    collapsedContent,
    className,
    isExpandedDefault = true,
    iconPosition = 'left',
    fullyClickable = false,
    hideAccordionAriaLabel = 'hide accordion content',
    showAccordionAriaLabel = 'show accordion content',
  } = props

  const [isExpanded, setIsExpanded] = useState(isExpandedDefault)

  const getAccordionContent = () => {
    if (isExpanded) {
      return <div>{expandedContent}</div>
    } else if (!isExpanded && collapsedContent) {
      return <div>{collapsedContent}</div>
    } else return null
  }

  return (
    <div
      className={`Accordion ${className ? className : ''} ${
        fullyClickable ? 'cursor-pointer' : ''
      }`}
      onClick={() => (fullyClickable ? setIsExpanded(!isExpanded) : null)}
    >
      <>
        {iconPosition === 'right' && (
          <div className="Accordion__content">{getAccordionContent()}</div>
        )}
        <button
          onClick={() => (!fullyClickable ? setIsExpanded(!isExpanded) : null)}
          aria-label={
            isExpanded ? hideAccordionAriaLabel : showAccordionAriaLabel
          }
          className="Accordion__button"
          data-testid="accordion-button"
        >
          <Icon
            className={`Accordion__icon ${!isExpanded ? '-rotate-90' : ''}`}
            icon={['fa', 'fa-chevron-down']}
          />
        </button>
      </>
      {iconPosition === 'left' && (
        <div className="Accordion__content">{getAccordionContent()}</div>
      )}
    </div>
  )
}

export default Accordion

import './Footer.css'
import { Icon } from '@xanadu_ai/xanadu-component-library'
import NewsletterSubscribe from './NewsletterSubscribe/NewsletterSubscribe'
import { Link } from 'react-router-dom'
import FooterContent from '../../../content/LandingPage/Footer'

const { title, description, twitterUrl, linkedInUrl, youTubeUrl, slackUrl } =
  FooterContent

const POLICY_LINKS = [
  { label: 'Code of Conduct', route: '/code-of-conduct' },
  { label: 'Terms & Conditions', route: '/terms-and-conditions' },
  { label: 'Privacy Policy', route: '/privacy-policy' },
]

const Footer = () => {
  return (
    <div className="Footer DarkBackgroundFooter">
      <div className="Footer__content">
        {title && <h2 className="text-3xl font-semibold mb-6">{title}</h2>}
        <div className="Social">
          <div className="w-full md:w-1/2">
            {description && (
              <h3 className="opacity-70 text-lg md:text-2xl font-medium">
                {description}
              </h3>
            )}
          </div>
          <div className="w-full md:w-5/12 mt-8 md:mt-0">
            <NewsletterSubscribe />
            <div className="SocialLinks">
              <a
                href={twitterUrl}
                target="_blank"
                rel="noreferrer"
                className="SocialLink"
                aria-label="Twitter"
                data-testid="social-link-twitter"
              >
                <Icon
                  icon={['fa-brands', 'fa-twitter']}
                  size="lg"
                  className="opacity-80"
                />
              </a>
              <a
                href={linkedInUrl}
                target="_blank"
                rel="noreferrer"
                className="SocialLink"
                aria-label="LinkedIn"
                data-testid="social-link-linkedin"
              >
                <Icon
                  icon={['fa-brands', 'fa-linkedin-in']}
                  size="lg"
                  className="opacity-80"
                />
              </a>
              <a
                href={youTubeUrl}
                target="_blank"
                rel="noreferrer"
                className="SocialLink"
                aria-label="YouTube"
                data-testid="social-link-youtube"
              >
                <Icon
                  icon={['fa-brands', 'fa-youtube']}
                  size="lg"
                  className="opacity-80"
                />
              </a>
              <a
                href={slackUrl}
                target="_blank"
                rel="noreferrer"
                className="SocialLink"
                aria-label="Slack"
                data-testid="social-link-slack"
              >
                <Icon
                  icon={['fa-regular', 'fa-comments']}
                  size="lg"
                  className="opacity-80"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="DarkBackgroundFooter">
        <div className="BottomFooter">
          <div>
            <span className="opacity-80">
              <Icon icon={['fa-regular', 'fa-copyright']} /> 2022 Xanadu. All
              rights reserved.
            </span>
          </div>
          <div className="w-full lg:w-1/3 flex justify-between mb-6 md:mb-12 flex-col sm:flex-row">
            {POLICY_LINKS.map((link, index) => (
              <Link
                key={index}
                to={link.route}
                target="_blank"
                rel="noopener noreferrer"
                className="PolicyLink"
              >
                {link.label}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer

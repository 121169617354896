export default {
  title: 'Become an insider!',
  description:
    'Sign up for the Xanadu monthly newsletter and be the first to know about upcoming events, latest releases, career opportunities and more.',
  twitterUrl: 'https://twitter.com/PennyLaneAI',
  linkedInUrl: 'https://www.linkedin.com/company/xanaduai/',
  youTubeUrl: 'https://www.youtube.com/c/XanaduAI',
  slackUrl:
    'https://xanadu-quantum.slack.com/join/shared_invite/zt-nkwn25v9-H4hituCb_PUj4idG0MhSug#/shared-invite/email',
}

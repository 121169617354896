import MailchimpSubscribe from 'react-mailchimp-subscribe'
import NewsletterForm from '../NewsletterForm/NewsletterForm'
import config from '../../../../config'

const NewsletterSubscribe = () => {
  return (
    <MailchimpSubscribe
      url={String(config.mailchimpEndpoint)}
      render={(props) => {
        // eslint-disable-next-line react/prop-types
        const { subscribe, status, message } = props
        return (
          <NewsletterForm
            status={status ? status : ''}
            onValidated={(formData) => subscribe(formData)}
            message={message}
          />
        )
      }}
    />
  )
}

export default NewsletterSubscribe

import CountdownSectionContent from '../../../content/LandingPage/CountdownSection'
import './CountdownSection.css'
import { CountdownTimer, HeroLayout } from '@xanadu_ai/xanadu-component-library'
import { useState, useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { AppStore } from '../../../services/apollo/store/store'

const {
  backgroundImage,
  eventStartTitle,
  eventEndTitle,
  nextEventStartTitle,
  nextEventStartDateTime,
} = CountdownSectionContent

/** Types for the countdown renderer parameters */
interface CountdownTimeDelta {
  total: number
  days: number
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
  completed: boolean
}

const CountdownSection = () => {
  const store = useReactiveVar(AppStore)
  const [countdownTitle, setCountdownTitle] = useState<string>('')
  const [countdownTime, setCountdownTime] = useState<string>('')

  const eventStartDateTime = store.event.schedule.startAt
  const eventEndDateTime = store.event.schedule.finishAt

  const isFutureDate = (date: string) => {
    const today = new Date()
    return new Date(date) > today
  }

  useEffect(() => {
    if (
      eventStartTitle &&
      eventStartDateTime &&
      isFutureDate(eventStartDateTime)
    ) {
      setCountdownTitle(eventStartTitle)
      setCountdownTime(eventStartDateTime)
    } else if (
      eventEndTitle &&
      eventEndDateTime &&
      isFutureDate(eventEndDateTime)
    ) {
      setCountdownTitle(eventEndTitle)
      setCountdownTime(eventEndDateTime)
    } else if (
      nextEventStartTitle &&
      nextEventStartDateTime &&
      isFutureDate(nextEventStartDateTime)
    ) {
      setCountdownTitle(nextEventStartTitle)
      setCountdownTime(nextEventStartDateTime)
    } else {
      setCountdownTitle('')
      setCountdownTime('')
    }
  }, [
    eventStartDateTime,
    eventEndDateTime,
    nextEventStartTitle,
    nextEventStartDateTime,
  ])

  const timeRemaining = (timeDelta: CountdownTimeDelta) => {
    const formatTimeUnit = (timeUnit: number) => {
      return timeUnit >= 10 ? timeUnit : `0${timeUnit}`
    }
    return (
      <div className="CountdownSection__countdown--times">
        <div>
          <div className="Number">{formatTimeUnit(timeDelta.days)}</div>
          <div className="NumberLabel">days</div>
        </div>
        <div className="Number">:</div>
        <div>
          <div className="Number">{formatTimeUnit(timeDelta.hours)}</div>
          <div className="NumberLabel">hours</div>
        </div>
        <div className="Number">:</div>
        <div>
          <div className="Number">{formatTimeUnit(timeDelta.minutes)}</div>
          <div className="NumberLabel">minutes</div>
        </div>
        <div className="Number">:</div>
        <div>
          <div className="Number">{formatTimeUnit(timeDelta.seconds)}</div>
          <div className="NumberLabel">seconds</div>
        </div>
      </div>
    )
  }

  if ((!countdownTitle.length || !countdownTime.length) && !backgroundImage) {
    return <></>
  } else if (!countdownTitle.length || !countdownTime.length) {
    return (
      <HeroLayout
        backgroundImage={`/images/${backgroundImage}`}
        backgroundPosition={'bottom'}
        className="CountdownSectionHero"
        size={'large'}
        data-testid="countdown-section-hero"
      ></HeroLayout>
    )
  } else {
    return (
      <section
        data-testid="countdown-section"
        className="CountdownSection"
        style={{
          backgroundImage: `url('/images/${backgroundImage}')`,
        }}
      >
        {countdownTitle.length > 0 && (
          <h2 className="CountdownSection__title">{countdownTitle}</h2>
        )}
        {countdownTime.length > 0 && (
          <div className="CountdownSection__countdown">
            <CountdownTimer date={countdownTime} renderer={timeRemaining} />
          </div>
        )}
      </section>
    )
  }
}

export default CountdownSection

import { useQuery, useReactiveVar } from '@apollo/client'
import KeycloakService from '../services/KeycloakService'
import { AppStore } from '../services/apollo/store/store'
import { DocumentNode } from 'graphql'

const useQueryEvent = (query: DocumentNode, config = {}) => {
  const store = useReactiveVar(AppStore)
  return useQuery(query, {
    ...config,
    skip: !store.event.id || !KeycloakService.isLoggedIn(),
    context: {
      headers: {
        'X-Event-ID': store.event.id,
      },
    },
  })
}

export default useQueryEvent

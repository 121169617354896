import { useState } from 'react'
import { Form } from '@xanadu_ai/xanadu-component-library'

interface NewsLetterFormProps {
  status: string
  onValidated: (email: { EMAIL: string }) => void
  message: string | Error | null
}

const NewsletterForm = (props: NewsLetterFormProps) => {
  const { status, onValidated, message } = props
  const [error, setError] = useState<string | null>(null)
  const [email, setEmail] = useState<string>('')

  const handleSubscribe = () => {
    const emailRegex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    setError(null)
    if (!email || !emailRegex.test(email)) {
      setError('Please enter a valid email address')
      return null
    } else {
      const isFormValidated = onValidated({ EMAIL: email })
      return isFormValidated
    }
  }

  return (
    <>
      <Form formName="newsLetterForm" onSubmit={handleSubscribe}>
        <div className="relative">
          <label htmlFor="email-input" className="sr-only">
            Enter your email
          </label>
          <input
            type="email"
            id="email-input"
            placeholder="Enter your email"
            onChange={(event) => setEmail(event?.target?.value ?? '')}
          />
          <button type="submit" className="emailSubmitButton">
            Submit
          </button>
        </div>
      </Form>
      {status === 'error' ||
        (error && (
          <div className="pl-4">
            <span className="text-error-1">{error}</span>
          </div>
        ))}
      {status === 'success' && (
        <div className="pl-4">
          <span>{message}</span>
        </div>
      )}
    </>
  )
}

export default NewsletterForm

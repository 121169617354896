import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import config from '../../config'
import KeycloakService from '../KeycloakService'
import { get } from 'lodash'

const httpLink = new HttpLink({ uri: config.graphqlUrl })

const authMiddleware = new ApolloLink((operation, forward) => {
  const headers = {
    ...operation.getContext().headers,
  }
  if (KeycloakService.isAuthenticated()) {
    headers.Authorization = `Bearer ${KeycloakService.getToken()}`
  }
  // add the authorization to the headers
  operation.setContext({
    headers: {
      ...headers,
    },
  })

  return forward(operation)
})

const logoutLink = onError(({ networkError }) => {
  if (get(networkError, 'statusCode') === 401) KeycloakService.doLogin()
})

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          eventChallenge: {
            merge: true,
          },
        },
      },
    },
  }),
  link: from([authMiddleware, logoutLink, httpLink]),
})

export default client

import { Loader } from '@xanadu_ai/xanadu-component-library'
import './FullScreenLoader.css'

const FullScreenLoader = () => {
  return (
    <div className="FullScreenLoader">
      <Loader className="m-auto" />
    </div>
  )
}

export default FullScreenLoader

// import { useState } from 'react'
// import { Button } from '@xanadu_ai/xanadu-component-library'
// import KeycloakService from '../../../services/KeycloakService'
import { NavLink, useLocation } from 'react-router-dom'
import PLC_LOGO from '../../../assets/images/plc_logo.png'
// import XANADU_LOGO from '../../../assets/images/xanadu_logo.svg'
import { navLinkItem } from '../Navbar'
// import { getWindowOrigin } from '../../../utils/WindowActions'
import './TopMenu.css'

const TopMenu = ({ navLink }: { navLink: navLinkItem[] }) => {
  const { pathname } = useLocation()
  // const name = `${KeycloakService.getTokenParsed()?.given_name}`
  // const [signOutButtonLabel, setSignOutButtonLabel] = useState(name)

  return (
    <>
      <NavLink
        to="/"
        state={{ previousPath: pathname }}
        className="TopMenu__logo"
      >
        <img src={PLC_LOGO} width="50px" alt="PennyLane Cloud logo" />
      </NavLink>
      <span className="TopMenu__links">
        {navLink.map(
          (link) =>
            link.enabled && (
              <NavLink
                key={link.id}
                data-testid={link.id}
                to={link.url}
                state={{ previousPath: pathname }}
                className={`${link.isActive ? 'TopMenu__link--active' : ''}`}
                onClick={link.onClick ? link.onClick : () => null}
              >
                {link.title}
              </NavLink>
            )
        )}
      </span>
      {/* <span className="ml-auto">
        {KeycloakService.isAuthenticated() ? (
          <button
            onClick={() => KeycloakService.doLogout(getWindowOrigin())}
            className="TopMenu__Button--gold"
            aria-label="Sign Out"
            onMouseLeave={() => setSignOutButtonLabel(name)}
            onMouseEnter={() => setSignOutButtonLabel('SIGN OUT')}
            onBlur={() => setSignOutButtonLabel(name)}
            onFocus={() => setSignOutButtonLabel('SIGN OUT')}
          >
            <span className="TopMenu__Button__content">
              <span className="hidden hover:visible TopMenu__Button__label uppercase font-semibold">
                {signOutButtonLabel}
              </span>
              <span className="TopMenu__Button__logo">
                <img src={XANADU_LOGO} width="20px" alt="Xanadu logo" />
              </span>
            </span>
          </button>
        ) : (
          <Button
            className="TopMenu__Button--gold"
            onClick={() => KeycloakService.doLogin()}
            label={
              <span className="TopMenu__Button__content">
                <span className="TopMenu__Button__label uppercase font-semibold">
                  Sign in
                </span>
                <span className="TopMenu__Button__logo">
                  <img src={XANADU_LOGO} width="20px" alt="Xanadu logo" />
                </span>
              </span>
            }
            type="basic"
          />
        )}
      </span> */}
    </>
  )
}

export default TopMenu

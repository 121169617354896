import { gql } from '@apollo/client'

export const CREATE_PLAYER = gql`
  # Registers new users
  mutation PlayerCreate($meta: JSON!) {
    playerCreate(meta: $meta) {
      player {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`

export const UPDATE_PLAYER_HANDLE = gql`
  # Updates user handle (username)
  mutation PlayerHandleUpdate($handle: String!) {
    playerHandleUpdate(handle: $handle) {
      player {
        handle
      }
      userErrors {
        message
      }
    }
  }
`

import { Routes, Route, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
// import AuthenticatedRoute from './components/AuthenticatedRoute/AuthenticatedRoute'
import EventRoute from './components/GetEventDetails/GetEventDetails'
import PlayerRoute from './components/GetPlayerDetails/GetPlayerDetails'
// import Dashboard from './pages/Dashboard/Dashboard'
import LandingPage from './pages/LandingPage/LandingPage'
import Navbar from './components/Navbar/Navbar'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import CodeOfConduct from './pages/CodeOfConduct/CodeOfConduct'
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions'
import { initGA } from './services/GoogleAnalytics'
import { usePageTracking } from './hooks/usePageTracking'

function App() {
  initGA()
  usePageTracking()
  return (
    <div id="container">
      <EventRoute>
        <PlayerRoute>
          <Navbar />
        </PlayerRoute>
        <Routes>
          <Route
            path="/"
            element={
              <PlayerRoute>
                <LandingPage />
              </PlayerRoute>
            }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/code-of-conduct" element={<CodeOfConduct />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          {/* <Route
            path="/dashboard/*"
            element={
              <AuthenticatedRoute>
                <PlayerRoute>
                  <Dashboard />
                </PlayerRoute>
              </AuthenticatedRoute>
            }
          /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </EventRoute>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </div>
  )
}

export default App

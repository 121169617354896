export default {
  title: `S’more than just fun`,
  description:
    `Up for grabs are career-enhancing opportunities, swag, certificates and more. What are you waiting for? For a full list of prizes, please see our [FAQ](#faq).`,
  headerImage: 'background_spacer.png',
  prizes: [
    {
      image: `Individual_Prize_—_Maria_Schuld_Book.png`,
      title: `Maria Schuld's Book`,
    },
    {
      image: `Individual_Prize_—_Residency_Interview.png`,
      title: `Residency Interview`,
    },
    {
      image: `Individual_Prize_—_Tour.png`,
      title: `Xanadu Lab Tour`,
    },
    {
      image: `Individual_Prize_—_3_Hours_of_Consulting.png`,
      title: `Consulting Time`,
    },
    {
      image: `Individual_Prize_—_Dock_and_Bay_Towel.png`,
      title: `Dock and Bay Towel`,
    },
    {
      image: `Individual_Prize_—_Swag_Pack.png`,
      title: `Swag Pack`,
    },
    {
      image: 'Individual_Prize_—_Trees.png',
      title: 'Virtual Swag: trees planted in your name',
    },
  ],
}

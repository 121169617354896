import { useEffect } from 'react'
import MarkdownLayout from '../../components/MarkdownLayout/MarkdownLayout'
import TermsContent from '../../content/TermsAndConditions.md'

const TermsAndConditions = () => {
  useEffect(() => {
    document.title = 'Terms and Conditions — PennyLane Code Camp'
  }, [])

  return (
    <MarkdownLayout
      markdownFile={TermsContent}
      pageTitle="Terms and Conditions"
      className="-mt-8"
    />
  )
}

export default TermsAndConditions

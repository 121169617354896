import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { scrollToSelector } from '../../utils/SelectorActions'
import { Icon } from '@xanadu_ai/xanadu-component-library'
import { useNavigate } from 'react-router-dom'
import { CONSENT_COOKIE } from '../../constants'
import './ScrollTopButton.css'

const ScrollTopButton = () => {
  const [cookies] = useCookies([CONSENT_COOKIE])
  const [scrollPosition, setScrollPosition] = useState(0)
  const navigate = useNavigate()

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
    if (position === 0) {
      window.history.replaceState(null, '', '/')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <button
      className={`scroll-top-button ${scrollPosition > 0 ? 'isVisible' : ' '} ${
        cookies[CONSENT_COOKIE] ? 'bottom-7' : 'bottom-10'
      }`}
      onClick={() => {
        scrollToSelector('.NavBar', 'smooth')
        navigate('/')
      }}
      aria-label="scroll to top of page"
    >
      <Icon icon={'fa-solid fa-arrow-up'} className="h-6 m-auto" />
    </button>
  )
}

export default ScrollTopButton

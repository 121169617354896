import { useState, useEffect } from 'react'
import Layout from '../Layout/Layout'
import { Card, Loader } from '@xanadu_ai/xanadu-component-library'
import ReactMarkdown from 'react-markdown'
import http from '../../services/HttpService'
import './MarkdownLayout.css'

const MarkdownLayout = ({
  markdownFile,
  pageTitle,
  className = '',
}: {
  markdownFile: string
  pageTitle: string
  className?: string
}) => {
  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState(``)

  useEffect(() => {
    const getFileContent = async () => {
      const response = await http.get(markdownFile)
      if (response.status === 200) setContent(response.data)
      setLoading(false)
    }
    getFileContent()
  })

  return (
    <Layout pageTitle={pageTitle} heroLayoutClassName="MarkdownLayout__hero">
      <div className={`MarkdownLayout layout-width-75 ${className}`}>
        <Card shadow="large">
          {loading ? (
            <Loader className="my-16" />
          ) : (
            <div className="content">
              <ReactMarkdown linkTarget="_blank">{content}</ReactMarkdown>
            </div>
          )}
        </Card>
      </div>
    </Layout>
  )
}

export default MarkdownLayout

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import LandingPageHero from './LandingPageHero/LandingPageHero'
import PackingList from './PackingList/PackingList'
import Prizes from './Prizes/Prizes'
import FAQSection from './FAQSection/FAQSection'
import CountdownSection from './CountdownSection/CountdownSection'
import Registration from './Registration/Registration'
import Footer from './Footer/Footer'
import ScrollTopButton from '../../components/ScrollTopButton/ScrollTopButton'
import { scrollToSelector } from '../../utils/SelectorActions'
import './LandingPage.css'

const LandingPage = () => {
  const location = useLocation()
  useEffect(() => {
    if (location.hash) scrollToSelector(location.hash, 'auto')
    document.title = 'PennyLane Code Camp'
  }, [])

  return (
    <div className="LandingPage">
      <LandingPageHero />
      <div className="LandingPage__content">
        <Registration />
        <PackingList />
        <Prizes />
        <CountdownSection />
        <FAQSection />
        <Footer />
        <ScrollTopButton />
      </div>
    </div>
  )
}

export default LandingPage

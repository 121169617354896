import { HeroLayout } from '@xanadu_ai/xanadu-component-library'
import backgroundImage from '../../assets/images/background/stars.png'
import NavigationCard from './NavigationCard'
import { useLocation } from 'react-router-dom'
import './Layout.css'

interface LayoutProps {
  pageTitle?: string
  pageSubTitle?: string
  children: React.ReactNode | string | null
  heroLayoutChildren?: React.ReactNode | string | null
  heroLayoutClassName?: string
  childrenClassName?: string
  heroLayoutSize?: 'small' | 'medium' | 'large'
}

const Layout = ({
  pageTitle,
  pageSubTitle,
  heroLayoutClassName,
  childrenClassName,
  children,
  heroLayoutChildren,
  heroLayoutSize = 'large',
}: LayoutProps): JSX.Element => {
  const location = useLocation()

  /** Routes that should not include the dashboard navigation card */
  const NO_NAVIGATION_CARD = [
    '/',
    '/privacy-policy',
    '/code-of-conduct',
    '/terms-and-conditions',
  ]

  return (
    <>
      <div className="Layout">
        <HeroLayout
          backgroundImage={backgroundImage}
          backgroundColor="#000"
          backgroundPosition={'top'}
          className={heroLayoutClassName}
          size={heroLayoutSize}
        >
          <div className="layout-width-75">
            {pageTitle && <h1 className="h1 text-white">{pageTitle}</h1>}
            {pageSubTitle && (
              <h2 className="font-normal text-xl text-white">{pageSubTitle}</h2>
            )}
            {heroLayoutChildren}
          </div>
        </HeroLayout>
        {!NO_NAVIGATION_CARD.includes(location.pathname) && <NavigationCard />}
        <main className={childrenClassName}>{children}</main>
      </div>
    </>
  )
}

export default Layout

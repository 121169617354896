import { gql } from '@apollo/client'

interface Schedule {
  startAt: string
  finishAt: string
}
export interface eventDetails {
  id: string
  name: string
  status: string
  registrationOpen: boolean
  schedule: Schedule
}

export const GET_EVENT_ID = gql`
  query GetEventId {
    events {
      id
      name
      status
      registrationOpen
      schedule {
        startAt
        finishAt
      }
    }
  }
`
